import React, {useEffect, useState} from 'react'
import {User, OrganizationMember, Role, AddOrganizationMembers, AddOrganizationMemberRoles, RemoveOrganizationMembers} from 'auth0'
import {message, Layout, Row, Col, Table, Typography, Card, Space, AutoComplete, Select, Modal, Input} from 'antd'
import {PlusOutlined} from '@ant-design/icons'
import capitalize from 'capitalize'
import Button from 'antd-button-color'
import 'antd-button-color/dist/css/style.css'

import {useAuthContext} from '../../auth/AuthProvider'
import {IOrganization, IIntegration} from '../../types'
import { SPONSORED_PRODUCTS_BASE_URL } from '../../constants'
import { hasAuth0Access, Auth0Roles } from '../../auth/Auth0Bridge'

export interface OrganizationUserManagementProps {
  organization_id: string
}

type Organization = IOrganization & {integration: IIntegration}

type MemberWithRole = OrganizationMember & {role: Auth0Roles}

const OrganizationUserManagement: React.FC<OrganizationUserManagementProps> = (props) => {
  const [loading, setLoading] = useState(false)
  const [tableIsLoading, setTableIsLoading] = useState(false)
  const [organization, setOrganization] = useState<Organization | null>(null)
  const [users, setUsers] = useState<User[]>([])
  const [organizationUsers, setOrganizationUsers] = useState<OrganizationMember[]>([])
  const [userOrganizationRoles, setUserOrganizationRoles] = useState<Role[]>([])
  const {authenticatedFetch} = useAuthContext()
  const [addUser, setAddUser] = useState<{user_id: string; role: Auth0Roles; name: string; email: string} | null>(null)
  const [addIsLoading, setAddIsLoading] = useState(false)
  const [editUserRoleIsLoading, setEditUserRoleIsLoading] = useState(false)
  const [editUserRole, setEditUserRole] = useState<MemberWithRole | null>(null)

  useEffect(() => {
    document.title = "User Management"
    setLoading(true)
    ;(async () => {
      try {
        const [
          loadedOrganization,
          loadedUsers,
          loadedOrganizationUsers,
          loadedUserRoles,
        ] = await Promise.all([
          authenticatedFetch<Organization>(
            SPONSORED_PRODUCTS_BASE_URL + `portal/user/organizations/${props.organization_id}`,
          ),
          authenticatedFetch<User[]>(
            SPONSORED_PRODUCTS_BASE_URL + `portal/users`,
          ),
          authenticatedFetch<OrganizationMember[]>(
            SPONSORED_PRODUCTS_BASE_URL + `portal/user/organizations/${props.organization_id}/users`,
          ),
          authenticatedFetch<Role[]>(
            SPONSORED_PRODUCTS_BASE_URL + `portal/user/organizations/${props.organization_id}/roles`,
          )
        ])
        setOrganization(loadedOrganization.data)
        setUsers(loadedUsers.data)
        setOrganizationUsers(loadedOrganizationUsers.data)
        setUserOrganizationRoles(loadedUserRoles.data)
      } catch (err) {
        message.error(`Failed to load with error: ${err}`)
      }
      setLoading(false)
    })()
  }, [props.organization_id])

  async function onAdd() {
    try {
      setAddIsLoading(true)
      if (addUser) {
        const body: AddOrganizationMembers & AddOrganizationMemberRoles = {
          members: [addUser.user_id],
          roles: [addUser.role],
        }
        const response = await authenticatedFetch<{message: string}>(
          SPONSORED_PRODUCTS_BASE_URL + `portal/user/organizations/${props.organization_id}/member`,
          'POST',
          body,
        )
        const members = await authenticatedFetch<OrganizationMember[]>(
          SPONSORED_PRODUCTS_BASE_URL + `portal/user/organizations/${props.organization_id}/users`,
        )
        setOrganizationUsers(members.data)
        message.success(response.data.message)
      }
    } catch (err) {
      message.error(`Failed to add user with error: ${err}`)
    }
    setAddIsLoading(false)
  }

  async function onRemove(id: string) {
    try {
      setEditUserRoleIsLoading(true)
      const body: RemoveOrganizationMembers = {
        members: [id],
      }
      const response = await authenticatedFetch<{message: string}>(
        SPONSORED_PRODUCTS_BASE_URL + `portal/user/organizations/${props.organization_id}/member`,
        'DELETE',
        body,
      )
      const members = await authenticatedFetch<OrganizationMember[]>(
        SPONSORED_PRODUCTS_BASE_URL + `portal/user/organizations/${props.organization_id}/users`,
      )
      setOrganizationUsers(members.data)
      message.success(response.data.message)
    } catch (err) {
      message.error(`Failed to remove user with error: ${err}`)
    }
    setEditUserRoleIsLoading(false)
  }

  async function onEdit(id: string) {
    try {
      setEditUserRoleIsLoading(true)
      const roles = await authenticatedFetch<Role[]>(
        SPONSORED_PRODUCTS_BASE_URL + `portal/user/organizations/${props.organization_id}/roles/${id}`,
      )
      setEditUserRole(
        Object.assign(
          {},
          organizationUsers.find(user => user.user_id === id),
          {role: roles.data && roles.data[0] ? roles.data[0].name : null}
        ) as any
      )
    } catch (err) {
      message.error(`Failed to retrieve user roles with error: ${err}`)
    }
    setEditUserRoleIsLoading(false)
  }

  async function onOk() {
    try {
      setEditUserRoleIsLoading(true)
      if (editUserRole) {
        const body: AddOrganizationMembers & AddOrganizationMemberRoles = {
          members: [editUserRole.user_id as string],
          roles: [editUserRole.role],
        }
        const response = await authenticatedFetch<{message: string}>(
          SPONSORED_PRODUCTS_BASE_URL + `portal/user/organizations/${props.organization_id}/roles`,
          'PUT',
          body,
        )
        setEditUserRole(null)
        message.success(response.data.message)
      }
    } catch (err) {
      message.error(`Failed to update user role with error: ${err}`)
    }
    setEditUserRoleIsLoading(false)
  }

  return (
    <Layout.Content style={{ width: '100%' }}>
      <Card
        loading={loading}
        title={
          organization ? capitalize.words(`Manage ${organization.title} Users`) : ''
        }
      >
        <Space direction="vertical" style={{width: '100%'}}>
          <Row style={{width: '100%'}}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Table
                loading={tableIsLoading}
                dataSource={organizationUsers}
                columns={
                  [
                    {
                      title: 'Username',
                      dataIndex: 'name',
                      key: 'name',
                    },
                    {
                      title: 'Email',
                      dataIndex: 'email',
                      key: 'email',
                    },
                    {
                      dataIndex: 'user_id',
                      render: (id: string, record: any) => {
                        return (
                          <Row justify="end" style={{width: '100%'}}>
                            <Col>
                              <Space direction="horizontal">
                                <Button
                                  ghost
                                  type="success"
                                  style={{ width: '100%' }}
                                  loading={editUserRoleIsLoading}
                                  onClick={() => {
                                    onEdit(id)
                                  }}
                                  disabled={!hasAuth0Access(userOrganizationRoles, Auth0Roles.Editor)}
                                >
                                  Edit
                                </Button>
                                <Button
                                  ghost type="danger"
                                  style={{ width: '100%' }}
                                  disabled={!hasAuth0Access(userOrganizationRoles, Auth0Roles.Editor)}
                                  loading={editUserRoleIsLoading}
                                  onClick={() => {
                                    onRemove(id)
                                  }}
                                >
                                  Remove
                                </Button>
                              </Space>
                            </Col>
                          </Row>
                        )
                      }
                    }
                  ]
                }
              />
            </Col>
          </Row>
          <Row style={{width: '100%'}}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Typography.Text strong>Add User</Typography.Text>
            </Col>
          </Row>
          <Row style={{width: '100%'}}>
            <Col xs={9} sm={9} md={9} lg={9} xl={9} xxl={9}>
             <AutoComplete
                placeholder="Search by Email"
                options={
                  users.map(user => {
                    return {
                      key: user.user_id,
                      value: user.email,
                    }
                  })
                }
                style={{ width: '100%' }}
                filterOption={(inputValue, option) =>
                  Boolean(option && option.value && option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1)
                }
                onSelect={(value: string, option: any) => {
                  setAddUser(Object.assign({}, addUser, {user_id: option.key}))
                }}
                allowClear
                onClear={() => {
                  setAddUser(null)
                }}
              />
            </Col>
            <Col span={1} />
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Row justify="end" style={{width: '100%'}}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Select
                    style={{ width: '100%' }}
                    value={addUser ? addUser.role : null}
                    onChange={(value: Auth0Roles) => {
                      setAddUser(
                        Object.assign({}, addUser, { role: value })
                      )
                    }}
                  >
                    {
                      Object.values(Auth0Roles).map(t => {
                        return (
                          <Select.Option value={t}>{t}</Select.Option>
                        )
                      })
                    }
                  </Select>
                </Col>
              </Row>
            </Col>
            <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
              <Row justify="end" style={{width: '100%'}}>
                <Col>
                  <Button
                    loading={addIsLoading}
                    icon={<PlusOutlined/>}
                    onClick={() => onAdd()}
                    disabled={!hasAuth0Access(userOrganizationRoles, Auth0Roles.Editor)}
                  >
                    Add
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Space>
      </Card>
      <Modal
        title="Edit User Role"
        visible={editUserRole !== null}
        onCancel={() => {
          setEditUserRole(null)
        }}
        confirmLoading={editUserRoleIsLoading}
        onOk={() => {
          onOk()
        }}
        width={750}
      >
        <Space direction="vertical" style={{width: '100%'}}>
          <Row style={{width: '100%'}}>
            <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
              <Typography.Text strong>User</Typography.Text>
            </Col>
            <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
              <Input
                style={{ width: '100%' }}
                value={editUserRole ? editUserRole.name : ''}
                disabled
              />
            </Col>
          </Row>
          <Row style={{width: '100%'}}>
            <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
              <Typography.Text strong>Role</Typography.Text>
            </Col>
            <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
              <Select
                style={{width: '100%'}}
                value={editUserRole ? editUserRole.role : null}
                onChange={(value: Auth0Roles) => {
                  setEditUserRole(
                    Object.assign({}, editUserRole, { role: value })
                  )
                }}
              >
                {
                  Object.values(Auth0Roles).map(t => {
                    return (
                      <Select.Option value={t}>{t}</Select.Option>
                    )
                  })
                }
              </Select>
            </Col>
          </Row>
        </Space>
      </Modal>
    </Layout.Content>
  )
}

export default OrganizationUserManagement