import React, { FunctionComponent, useState, useEffect } from 'react';
import './index.css';

import { LoadingScreen } from '../../../components/Loader/Loader';
import { useAuthContext } from '../../../auth/AuthProvider';
import { SPONSORED_PRODUCTS_BASE_URL } from '../../../constants';
import { IIntegration, ILayout, IOrganization } from '../../../types';
import { Card, Divider, message, Tabs, Typography } from 'antd';
import {Role} from 'auth0'

import CarroselItem from '../../../components/carroselItem';

const { TabPane } = Tabs;

type Integration = IIntegration & { associated_organization: IOrganization };

interface SettingsProps {
  integration: IIntegration,
}

export const Settings: FunctionComponent<SettingsProps> = (props) => {

  //Update Layout endpoint.
  ///layout/:layout_id/integration/:integration_id/update
  const { user, authenticatedFetch } = useAuthContext()
  const [layouts, setLayouts] = useState<ILayout[]>([])
  const [isLoadingLayouts, setIsLoadingLayouts] = useState(false)
  const [integration, setIntegration] = useState<IIntegration>()
  const [userOrganizationRoles, setUserOrganizationRoles] = useState<Role[]>([])

  useEffect(() => {
    document.title = "Carousel Settings"
    //console.log("useEffect - integration")
    //console.log(props.integration)
    //console.log(integration)

    if (props.integration) {

      setIntegration(props.integration)
      setIsLoadingLayouts(true);
      ; (async () => {
        try {

          const response = await authenticatedFetch<Integration>(
            SPONSORED_PRODUCTS_BASE_URL + `api/integration/${props.integration._id}?populate=associated_organization&populate=associated_layouts`,
          )

          setLayouts((response.data as IIntegration).associated_layouts)

          const userRolesResponse = await authenticatedFetch<Role[]>(
            SPONSORED_PRODUCTS_BASE_URL + `portal/user/organizations/${response.data.associated_organization._id}/roles`,
          )

          setUserOrganizationRoles(userRolesResponse.data)

        } catch (err) {
          message.error(`failed to load organizations: ${err.message}`)
        }
        setIsLoadingLayouts(false)
      })()
    }
  }, [props.integration]);

  return isLoadingLayouts
    ? (
      <LoadingScreen />
    )
    : (
      <>
        {/* <Divider orientation="left">
          Carousel Settings
        </Divider> */}
        <Typography.Title level={3}>Carousel Settings</Typography.Title>
        <Card>
          <Tabs defaultActiveKey="0" type="card" size="middle">
            {
              layouts.map((layout, index) => (
                <TabPane tab={layout.title} key={index}>
                  {
                    layout.associated_sections?.map((associated_section, index) => (
                      <CarroselItem
                        layout={layout}
                        associatedSections={associated_section}
                        index={index}
                        key={index}
                        userRoles={userOrganizationRoles}
                      />
                    ))
                  }
                </TabPane>
              ))
            }
          </Tabs>
        </Card>
      </>
    );
}

export default Settings;