import { Button, ButtonProps, Result } from 'antd';
import React, { useEffect, useState } from 'react';
import { LoadingScreen } from '../../components/Loader/Loader';
import { IIntegration, IIntegrationAnalytics } from '../../types';
import { useNavigate } from "react-router-dom";

import './index.css';
interface OverviewProps {
  integration? : IIntegration,
}

function Overview(props: OverviewProps) {

  const [isLoading, setLoading] = useState(true);

  const [integration, setIntegration] = useState<IIntegration>()


  //Colcci: 610042f4d53f2a0716eea4cd
  //Triton: 61e19556384326364dbb2d23
  //Forum: 61e1d4bb384326364dbb2f8a
  //Oasis: 61e1d60e384326364dbb2fab
  //Madeira Madeira: 5d42f6ddfe182b4831e20fe7

  useEffect(() => {

    //console.log("useEffect - integration")
    //console.log(props.integration?._id)
    //console.log(integration?._id)
    document.title = "Jewel ML - Overview"
    setLoading(true);

    if (props.integration) {

      setIntegration(props.integration)

    }

    setLoading(false)

    //console.log("end useEffect - integration")

  }, [props.integration]);


  const getIframe = (source: string, index: number) => {
    return (
      <iframe
        title='Analytics'
        width={"100%"}
        className='analytics-iframe'
        src={source}
        frameBorder="0"
        key={index}
        onLoad={() => {
          setLoading(false);
        }}
      />
    )
  }

  let navigate = useNavigate();

  return (
    <div>
      {isLoading ? <LoadingScreen /> : null}
      {(integration && integration?.integration_properties?.ovv_url!) ?
        getIframe(integration?.integration_properties?.ovv_url!, 0) :
        <Result
        status="404"
        title="Not ready.. yet!"
        subTitle="Your personalized Overview page will be available soon!"
        extra={<Button type="primary" onClick={() => navigate('/help')}>Need help?</Button>}
      />}
    </div>
  );
}

export default Overview;

